import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../images/logo.webp';
import { useTranslation } from 'react-i18next';
import langIcon from '../images/icon/LangIcon1.webp';

const Navbar = ({ ismenuopen }) => {
  const { i18n } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation(); // Get the current location

  const languages = [
    { code: 'en', name: 'Eng' },
    { code: 'kn', name: 'Kan' },
    { code: 'ta', name: 'Tam' },
  ];

  const selectLanguage = (code) => {
    i18n.changeLanguage(code);
    setIsHovered(false);
  };

  // Check if the current path is the homepage
  const isHomePage = location.pathname === '/';

  return (
    <NavBarContainer ismenuopen={ismenuopen}>
      <LogoWrapper>
        <Link to="/">
          <LogoContainer>
            <img src={logo} alt="AP Logo" />
            <BusinessName>Aushadha Poorna</BusinessName>
          </LogoContainer>
        </Link>
      </LogoWrapper>
      {(!isHomePage || ismenuopen) && ( // Show LanguageIcon if not on homepage OR menu is open
        <LanguageContainerWrapper
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <LanguageIcon src={langIcon} alt="Select Language" />
          {isHovered && (
            <LanguageContainer>
              {languages.map((lang, index) => (
                <LanguageItem
                  key={lang.code}
                  onClick={() => selectLanguage(lang.code)}
                >
                  {lang.name}
                  {index < languages.length - 1 && ', '}
                </LanguageItem>
              ))}
            </LanguageContainer>
          )}
        </LanguageContainerWrapper>
      )}
    </NavBarContainer>
  );
};

export default Navbar;

// Styled Components

const NavBarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: ${(props) =>
    props.ismenuopen
      ? 'rgba(225, 225, 225, 0.4)'
      : 'rgba(225, 225, 225, 0.1)'};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    flex-direction: row;
    padding: 10px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 65px;
    height: auto;
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;

const BusinessName = styled.h1`
  font-size: 1.5rem;
  color: #152b14;
  font-weight: 15;
  margin: 0;
  font-family: 'Alfa Slab One';

  @media (max-width: 20px) {
    font-size: 1rem;
  }
`;

const LanguageContainerWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover > div {
    opacity: 1;
    transform: translateX(0);
  }
`;

const LanguageIcon = styled.img`
  width: 25px;
  height: 25px;
  padding: 8.5px;
  transition: transform 0.1s ease;

  &:hover {
    transform: scale(1.5);
  }
`;

const LanguageContainer = styled.div`
  position: absolute;
  top: 0;
  left: -97px;
  background: rgba(225, 225, 225, 0.8);
  border-radius: 8px;
  color: #152b14;
  padding: 8.5px;
  width: 180px;
  z-index: 100;
  white-space: nowrap;

  opacity: 1;
  transform: translateX(-20px);
  transition: all 0.3s ease-in-out;
`;

const LanguageItem = styled.span`
  cursor: pointer;
  font-size: 1rem;
  margin-right: 5px;

  &:hover {
    color: #f1f1f1;
    text-decoration: underline;
  }
`;
