import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import MenuPage from './MenuPage';
import Navbar from '../components/Navbar';

// Import banner images
import soupBanner1 from '../images/banner1.webp';
import soupBanner3 from '../images/banner3.webp';
import soupBanner2 from '../images/banner2.webp';

const HomePage = () => {
  const [ismenuopen, setismenuopen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const menuContainerRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  const handleMenuToggle = () => {
    setismenuopen(!ismenuopen);
  };

  // Auto-scroll logic
  useEffect(() => {
    if (ismenuopen) {
      const scrollContainer = menuContainerRef.current;

      // Function to handle scrolling
      const scrollMenu = () => {
        if (scrollContainer && !hovered) {
          if (scrollContainer.scrollHeight === scrollContainer.scrollTop + scrollContainer.clientHeight) {
            scrollContainer.scrollTop = 0; // Reset to top when it reaches the bottom
          } else {
            scrollContainer.scrollTop += 1; // Scroll down by 1px
          }
        }
      };

      // Set the interval for auto-scrolling
      scrollIntervalRef.current = setInterval(scrollMenu, 30); // Adjust the speed here

      // Clear the interval when menu is closed
      return () => clearInterval(scrollIntervalRef.current);
    }
  }, [ismenuopen, hovered]);

  return (
    <div>
      {/* Navbar Section */}
      <Navbar ismenuopen={ismenuopen} />

      {/* Banner Section with Slider */}
      <Banner>
        <Slider {...sliderSettings}>
          <Slide>
            <ImageBackground src={soupBanner1} />
            <HeroContent>
              <h1>Fresh Soups on the Go</h1>
              <p>Welcome to the Soup Truck. Every bowl is a warm hug.</p>
              <CTA>
                <button onClick={handleMenuToggle}>Explore Menu</button>
              </CTA>
            </HeroContent>
          </Slide>
          <Slide>
            <ImageBackground src={soupBanner3} />
            <HeroContent>
              <h1>Wellness in Every Spoon</h1>
              <p>Perfectly crafted soups for every craving, nurturing your body</p>
              <CTA>
                <button onClick={handleMenuToggle}>Browse Flavors</button>
              </CTA>
            </HeroContent>
          </Slide>
          <Slide>
            <ImageBackground src={soupBanner2} />
            <HeroContent>
              <h1>Healing in Every Bowl</h1>
              <p>Taste the bowl of our freshly made soups, designed to heal and energize</p>
              <CTA>
                <button onClick={handleMenuToggle}>Order Now</button>
              </CTA>
            </HeroContent>
          </Slide>
        </Slider>
      </Banner>

      {/* Overlay Menu Section */}
      {ismenuopen && (
        <MenuOverlay>
          <button className="close-button" onClick={handleMenuToggle}>
            &times;
          </button>
          <ScrollableMenuContainer ref={menuContainerRef}
           onMouseEnter={() => setHovered(true)}
           onMouseLeave={() => setHovered(false)}
          >
            <MenuPage /> {/* Render the full menu page */}
          </ScrollableMenuContainer>
        </MenuOverlay>
      )}
    </div>
  );
};

export default HomePage;

// Styled Components
const Banner = styled.div`
  .slick-dots {
    bottom: 20px;
  }

  .slick-dots li button:before {
    color: white;
  }
`;

const Slide = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  color: white;
`;

const ImageBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1)), url(${(props) => props.src}) no-repeat center center/cover;
  z-index: -1;
`;

const HeroContent = styled.div`
  position: absolute;
  bottom: 180px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 800px;
  padding: 20px;
  margin-bottom: 120px;
  text-align: center;
  width: 90%;

  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 30px;
    line-height: 1.5;
  }
`;

const CTA = styled.div`
  button {
    background: #152b14;
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: #e64a19;
    }
  }
`;

const MenuOverlay = styled.div`
  position: fixed;
  top: 67px;
  left: 0;
  width: 100vw;
  // height: 100vh;
  height: calc(100vh - 70px);
  background: rgba(225, 225, 225, 1);
  color: #152b14;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    background: none;
    color: #152b14;
    border: none;
    cursor: pointer;
  }
`;

const ScrollableMenuContainer = styled.div`
  width: 99%;
  height: 98%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;

  /* Add smooth scrolling */
  scrollbar-width: thin;
  scrollbar-color: #152b14 #252525;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #152b14;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #252525;
  }
`;

