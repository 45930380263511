import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// import soupBanner from '../images/menu/soup-banner.webp';
import soupBanner0 from '../images/menu/hibiscus.webp';
import soupBanner1 from '../images/menu/ponnanganni.webp';
import soupBanner2 from '../images/menu/Thuthuvalai.webp';
import soupBanner3 from '../images/menu/keelanelli.webp';
import soupBanner4 from '../images/menu/vendhayakeerai.webp';
import soupBanner5 from '../images/menu/vazhaithandu.webp';
import soupBanner6 from '../images/menu/manathkkali.webp';
import soupBanner7 from '../images/menu/pirandai.webp';
import soupBanner8 from '../images/menu/mudakathan.webp';
import soupBanner9 from '../images/menu/moringa.webp';
import soupBanner10 from '../images/menu/mushroom.webp';
import soupBanner11 from '../images/menu/veg.webp';
import soupBanner12 from '../images/menu/amla.webp';
import soupBanner13 from '../images/menu/jamun.webp';
import soupBanner14 from '../images/menu/aavarampoo.webp';
import soupBanner15 from '../images/menu/kollu.webp';

// Menu items with images
const menuItems = [
  { key: 0, image: soupBanner0 },
  { key: 1, image: soupBanner1 },
  { key: 2, image: soupBanner2 },
  { key: 3, image: soupBanner3},
  { key: 4, image: soupBanner4 },
  { key: 5, image: soupBanner5 },
  { key: 6, image: soupBanner6 },
  { key: 7, image: soupBanner7 },
  { key: 8, image: soupBanner8 },
  { key: 9, image: soupBanner9 },
  { key: 10, image: soupBanner10 },
  { key: 11, image: soupBanner11 },
  { key: 12, image: soupBanner12 },
  { key: 13, image: soupBanner13 },
  { key: 14, image: soupBanner14 },
  { key: 15, image: soupBanner15 },
];

const MenuPage = () => {
  const { t, i18n } = useTranslation();
  const translatedItems = t('menu.items', { returnObjects: true });

  return (
    <MenuContainer>
      <h2>{t('menu.title')}</h2>
      <LanguageIndicator>{t('menu.language', { lng: i18n.language })}</LanguageIndicator>
      <MenuGrid>
        {translatedItems.map((item, index) => (
          <MenuItem key={item.key}>
            <img src={menuItems[index]?.image} alt={item.name} />
            <h3>{item.name}</h3>
            <p>{item.price}</p>
            <Description>{item.description}</Description>
          </MenuItem>
        ))}
      </MenuGrid>
    </MenuContainer>
  );
};

export default MenuPage;

const MenuContainer = styled.div`
  text-align: center;
  padding: 60px 20px;

  h2 {  
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
`;

const LanguageIndicator = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  font-style: italic;
`;

const MenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const MenuItem = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 15px;
  }

  // &:hover {
  //   transform: translateY(-10px);
  // }
  &:hover {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #777;
  margin-top: 10px;
  line-height: 1.4;
`;